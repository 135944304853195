import Moment from "moment";
import {Button, Card, Modal, Table} from "antd";
import {useEffect, useState} from "react";
import {ScheduleOutlined} from "@ant-design/icons";
import useWindowDimensions, {isLocalIT} from "../../utils/utils";
import Search from "antd/es/input/Search";
import {useTranslation} from "react-i18next";

const Documents = (props) => {

    const [isModalVisible, setIsModalVisible] = useState({
        visible: false,
        forTable: null
    });
    const [sortedData, setSortedData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [id, setId] = useState(0);
    const [page, setPage] = useState(1);
    const {height, width} = useWindowDimensions();
    const [policySections, setPolicySections] = useState([]);

    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (props.for === 'policies') {
            // get json data from file in public assets folder
            fetch(`${process.env.PUBLIC_URL}/assets/sorting/policies.json`)
                .then(response => response.json())
                .then(data => {
                    setPolicySections(data);
                    setSortedData(props.data);
                    setFilteredData(props.data);
                    console.log(props.data);
                })
                .catch(error => console.error(error));
        } else {
            setSortedData(props.data);
            setFilteredData(props.data);
        }
    }, [])

    const onSearch = (value) => {
        if (value.length > 0) {
            setFilteredData(sortedData.filter(i =>
                i.title.nameEng.toLowerCase().includes(target.value.toLowerCase()) ||
                i.code.toLowerCase().includes(target.value.toLowerCase())
            ))
        } else {
            setFilteredData(sortedData)
        }
    }

    const onChange = ({target}) => {
        if (target.value.length > 0) {
            setFilteredData(sortedData.filter(i =>
                i.title.nameEng.toLowerCase().includes(target.value.toLowerCase()) ||
                i.code.toLowerCase().includes(target.value.toLowerCase())
            ))
        } else {
            setFilteredData(sortedData)
        }
    }

    const columns = (forData = []) => {
        return [
            {
                title: i18n.language === 'ar' ? "الاسم" : "Name",
                dataIndex: "name",
                align: i18n.language === 'ar' ? "right" : "left",
                width: '80%',
                render: (item, record, index) => {

                    return (
                        <a
                            onClick={() => {
                                setId((page - 1) * 10 + index);
                                setIsModalVisible((prevState) => ({
                                    ...prevState,
                                    visible: true,
                                    forTable: forData
                                }));
                            }}
                        >
                            {i18n.language === 'ar' ? record.title.nameArb : record.title.nameEng}
                        </a>
                    )
                },
                sorter: (a, b) => a.title.nameEng.localeCompare(b.title.nameEng),
            },
            {
                title: i18n.language === 'ar' ? "التاريخ" : "Date",
                dataIndex: "updatedAt",
                align: "center",
                render: (date, record) => <div>{Moment(record.date).format("DD/MM/yyyy")}</div>,
                sorter: (a, b) => Moment(a.date).unix() - Moment(b.date).unix()
            }
        ]
    }

    const buildLink = (file, sectionId) => {

        if (props.for === 'policies') {
            const forData = isModalVisible.forTable;
            return `${process.env.REACT_APP_PORTAL_FILES_URL}/${props.for}/${forData[id].id}/${sectionId}/${file}`;
        }

        return `${process.env.REACT_APP_PORTAL_FILES_URL}/${props.for}/${filteredData[id].id}/${sectionId}/${file}`;
    };

    const renderFiles = (arr, index) => {

        let section = [
            {
                id: 'main',
                title: i18n.language === 'ar' ? 'الملفات الرئيسية' : 'Main Files'
            },
            {
                id: 'attachment',
                title: i18n.language === 'ar' ? 'المرفقات' : 'Attachments'
            },
            {
                id: 'learningMaterials',
                title: i18n.language === 'ar' ? 'مواد التعلم' : 'Learning Materials'
            },
        ]

        const main = arr;
        const title = section[index].title;
        if (main.length < 1) {
            return (
                <Card
                    title={title}
                    key={index}
                >
                    <p>
                        {i18n.language === 'ar' ? 'لا توجد ملفات' : 'No files available'}
                    </p>
                </Card>
            );
        }

        return (
            <Card
                key={`${title}-${index}`}
                title={title}
            >
                {main.map((item) => (
                    <div key={`${item}-${index}`}>
                        <a href={buildLink(item, section[index].id)} target="_blank" rel="noreferrer">
                            {item}
                        </a>
                    </div>
                ))}
            </Card>
        );
    };

    const findPolicySection = (policyCode) => {
        for (const section of policySections) {
            if (section.codes.includes(policyCode)) {
                return section;
            }
        }
        return null;
    };

    const groupedPolicies = filteredData.reduce((acc, policy) => {
        const section = findPolicySection(policy.code);
        if (section) {
            if (!acc[section.key]) {
                acc[section.key] = {
                    title: section.title,
                    order: section.order,
                    policies: []
                };
            }
            acc[section.key].policies.push(policy);
        }
        return acc;
    }, {});

    const sortedSections = Object.values(groupedPolicies).sort((a, b) => a.order - b.order);

    return (
        <div
            className="mycontainer"
            style={{
                minHeight: (height * 0.65),
                padding: 15,
            }}
            dir={i18n.dir()}
        >
            {
                props.for === 'policies' &&
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        type="primary"
                        size="small"
                        icon={<ScheduleOutlined/>}
                        style={{
                            marginTop: 10,
                            marginBottom: 25,
                            display: 'inline-flex',
                            alignItems: 'center',
                            flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row'
                        }}
                        onClick={() => window.open(`${process.env.REACT_APP_PORTAL_FILES_URL}/extra/index/Policies%20That%20Have%20Been%20Distributed%20to%20PHC%20Centers.pdf`, "_blank")}
                    >
                        {i18n.language === 'ar' ? 'الفهرس' : 'index'}
                    </Button>
                </div>
            }
            {
                isModalVisible.forTable && isModalVisible.forTable.length > 0 ? <Modal
                    key={isModalVisible.forTable[id]}
                    title={i18n.language === 'ar' ? isModalVisible.forTable[id].title.nameArb : isModalVisible.forTable[id].title.nameEng}
                    open={isModalVisible.visible}
                    onCancel={() => {
                        setIsModalVisible((prevState) => ({
                            ...prevState,
                            visible: false,
                            forTable: null
                        }));
                        setId(0);
                    }}
                    onOk={() => {
                        setIsModalVisible((prevState) => ({
                            ...prevState,
                            visible: false,
                            forTable: null
                        }));
                        setId(0);
                    }}
                    footer={null}
                >
                    {/*temporary change order for the files for the MOT-IT problem*/}
                    {/*for the Dev it was 2,0,1*/}
                    {/*for the localIT it was 2,1,0*/}
                    {/*for the else it was 0,1,2*/}
                    {/*the syntax isDev() ? 2 : isLocalIT() ? 2 : 0*/}
                    {renderFiles(isModalVisible.forTable[id].file[0].main, 0)}
                    <br/>
                    {renderFiles(isModalVisible.forTable[id].file[1].attachment, 1)}
                    <br/>
                    {renderFiles(isModalVisible.forTable[id].file[2].learningMaterials, 2)}
                </Modal> : null
            }
            <Search
                placeholder={i18n.language === 'ar' ? ` البحث عن ${t(`views.documents.${props.title.toLowerCase()}`)}` : `Search for ${props.title}`}
                allowClear
                onSearch={onSearch}
                onChange={onChange}
                style={{width: '100%', height: 50, marginBottom: 15}}
                size="large"
            />
            {
                props.for === 'policies' ?
                    sortedSections.map((section, index) => (
                        <div
                            key={section.title.en}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    backgroundColor: '#FAFAFA',
                                    padding: 10,
                                }}
                            >
                                <h4
                                    style={{
                                        color: '#1c3564',
                                    }}
                                >
                                    {i18n.language === 'ar' ? section.title.ar : section.title.en}
                                </h4>
                            </div>
                            <Table
                                columns={columns(section.policies)}
                                dataSource={section.policies}
                                rowKey={record => record.id}
                                pagination={false}
                                onChange={(pagination, filters, sorter) => {

                                    if (sorter.field === 'name') {
                                        setSortedData(sorter.order === 'ascend' ? section.policies.sort((a, b) => a.title.nameEng.localeCompare(b.title.nameEng)) : section.policies.sort((a, b) => b.title.nameEng.localeCompare(a.title.nameEng)))
                                    } else if (sorter.field === 'updatedAt') {
                                        setSortedData(sorter.order === 'ascend' ? section.policies.sort((a, b) => Moment(a.date).unix() - Moment(b.date).unix()) : section.policies.sort((a, b) => Moment(b.date).unix() - Moment(a.date).unix()))
                                    }
                                }}
                            />
                        </div>
                    )) :
                    <Table
                        columns={columns(filteredData)}
                        dataSource={filteredData}
                        rowKey={record => record.id}
                        pagination={{
                            pageSize: 10,
                            style: {
                                flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row'
                            },
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        onChange={(pagination, filters, sorter) => {

                            if (sorter.field === 'name') {
                                setSortedData(sorter.order === 'ascend' ? filteredData.sort((a, b) => a.title.nameEng.localeCompare(b.title.nameEng)) : filteredData.sort((a, b) => b.title.nameEng.localeCompare(a.title.nameEng)))
                            } else if (sorter.field === 'updatedAt') {
                                setSortedData(sorter.order === 'ascend' ? filteredData.sort((a, b) => Moment(a.date).unix() - Moment(b.date).unix()) : filteredData.sort((a, b) => Moment(b.date).unix() - Moment(a.date).unix()))
                            }
                        }}
                    />
            }
        </div>
    );
}

export default Documents;
